import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"

import TabBar from "../components/policyTabs"
import Container from "../components/container"

export default function Rfunction({ location }) {
  return (
    <Page>
      <SEO title="Referral Terms and Conditions" />
      <Container>
        <h1 className="tandc">Terms and Conditions</h1>
      </Container>
      <TabBar location={location} />
      <Container>
        <div className="text-container">
          <h1>Referral Program Terms and Conditions</h1>
          <p>
            Anyone who shares their unique referral link (“Referrers”) can earn
            bitcoin through TravelbyBit’s referral program (“The Program”) by
            referring friends (“Referees”) to become new users on TravelbyBit
            (https://travelbybit.com) (the “Site”). For more information on
            Rewards, please see our{" "}
            <a href="/rewards-terms-and-conditions">
              Rewards Terms and Conditions
            </a>
            .
          </p>
          <p>
            Referrers and referees (“Participants”) must agree to these Terms
            and Conditions, which form part of the{" "}
            <a href="/terms-of-use">Terms of Use</a>, in order to participate in
            The Program. All Participants must be 18 years or older in order to
            be eligible to participate in The Program.
          </p>
          <h2>1. Obtaining the Reward</h2>
          <p>
            When a referral is successfully completed, the Referee will receive
            US$25 in Travel Credits; once the Referee places an Eligible
            Booking, the Referrer will receive US$10 in Bitcoin rewards (both
            rewards are henceforth collectively referred to as the "Reward").
          </p>
          <p>
            A successfully completed referral must satisfy both of the following
            requirements:
          </p>
          <p>
            (i) The Referee clicks the Referrer’s referral link to create a
            valid TravelbyBit account; and
          </p>
          <p>
            (ii) The account creation must comply with the 
            <a href="/terms-of-use">Terms of Use</a>, including the requirement
            that Referrers and Referees cannot be the same person.
          </p>
          <p>
            The Referrer may refer up to ten (10) new accounts and can be the
            Referee once if successfully referred by another account at the sign
            up stage. Therefore, the maximum that can be earned through this
            Referral Program is 10 x US$10 in BTC rewards as the Referrer and 1
            x $25 in Travel Credits as the Referee.
          </p>
          <h2>2. Eligible Bookings</h2>
          <p>
            For a booking to be an Eligible Booking for the purposes of The
            Program, the following requirements must be met:
          </p>
          <p>(i) The booking must be booked and purchased via the Site;</p>
          <p>
            (ii) The booking must be equal to or greater than US$75 in value,
            and;
          </p>
          <p>
            (iii) The Referee must complete the flight or stay. For flight
            bookings, the Reward will be awarded after the Referee has completed
            their flight. For hotel bookings, the Reward will be awarded after
            the Referee has completed their stay.
          </p>
          <p>
            Please note that the Reward will not be awarded before the
            reservation is considered an Eligible Booking, as per the above
            requirements. If the reservation is cancelled, neither the Referrer
            nor the Referee will receive the Reward, as that referral will be
            deemed incomplete.
          </p>
          <h2>3. Reward Balance</h2>
          <p>
            Once the Referee has used the referral link to create an account and
            place a booking, the Reward information can be viewed in both the
            Referrer and Referee’s rewards page. The reward type will be
            displayed as ‘Referral’ and the status will either be ‘Processed’ or
            ‘Pending’, depending on whether an Eligible Booking has been
            completed or is yet to be completed. Processed Rewards will also
            display the date that the Reward was received.
          </p>
          <p>
            Rewards are withdrawable once the total Rewards balance has reached
            an equivalent value of US$25.
          </p>
          <p>
            For more information on Rewards, please visit <br />
            <Link to="/rewards-terms-and-conditions">
              https://travelbybit.com/rewards-terms-and-conditions.
            </Link>
          </p>
          <h2>4. Referral Links</h2>
          <p>
            Referral links are only permitted to be used for personal and
            non-commercial purposes. If TravelbyBit reasonably suspects that a
            Participant is distributing referral links for commercial or other
            purposes that are deemed to violate the integrity of The Program,
            TravelbyBit may, at its discretion, decide to remove the customer
            from The Program, resulting in the Participant’s Rewards balance
            obtained from The Program being forfeited. This includes paying to
            advertise referral links, distributing referral links by using bots
            or other automated systems, or mass messaging the referral link to
            strangers through any channel.
          </p>
          <p>
            The Referee may only use one referral link when creating their
            account. Only the Referrer whose link was actually used by the
            Referee to sign up will be eligible to receive the Reward. No other
            referral links or incentives, whether obtained from TravelbyBit or
            elsewhere, may be combined in conjunction with The Program.
          </p>
          <h2>5. Information Shared Between Referrers and Referees</h2>
          <p>
            As part of the Program, TravelbyBit may share the Referee’s name
            with the Referrer to inform the Referrer about the status of the
            Referee’s Eligible Booking. This is solely done for informational
            purposes. No additional information about the Referee’s booking
            other than its status (Pending or Processed) will be disclosed to
            the Referrer. In the event that the Referee does not wish the
            Referrer to receive the status of their booking, the Referee should
            not participate in The Program.
          </p>
          <h2>6. Changes to The Program and User Accounts</h2>
          <p>
            TravelbyBit may, at its discretion, temporarily or permanently
            change or discontinue The Program, or a customer’s ability to
            participate in The Program. If a customer is found to be acting in
            breach of these Terms and Conditions, or in an illegal, deceptive or
            fraudulent manner, TravelbyBit reserves the right to render the
            customer's Rewards void and disqualify the customer from receiving
            Rewards in the future. To determine if any such breach has occurred
            with respect to referral activities, TravelbyBit reserves the right
            to investigate all referral activities and make a decision based on
            these findings.
          </p>
          <h2>7. Updates to these Terms and Conditions</h2>
          <p>
            TravelbyBit reserves the right to modify these Terms and Conditions
            at any time at its discretion with or without notice. Any changes
            will be immediately effective from the time the changes are
            published on the website. Changes can be made without prior notice
            and without externally communicating these changes outside of the
            webpage where these Terms are displayed on the Site. Participation
            in the Program following any changes will be deemed to constitute
            acceptance of the updated Terms and Conditions.
          </p>
          <p>
            If you believe that your Rewards balance is inaccurate or has not
            been updated, please contact us via the{" "}
            <a href="https://travelbybit.com/contact-us">support page</a> and
            provide us with any relevant information in relation to your
            account. The decision regarding the balance will be made at
            TravelbyBit’s discretion and will be final.
          </p>
          <h2>8. Severability</h2>
          <p>
            Where a provision or part of a provision of The Program is deemed to
            be void, invalid or unenforceable, that provision or part of the
            provision will be withdrawn in that instance. The enforceability of
            the remaining provisions will not be affected.
          </p>
          <h2>9. Jurisdiction</h2>
          <p>
            These Terms and Conditions are subject to Australian law, and
            nothing contained within these Terms and Conditions, is intended to
            restrict or exclude the rights of a customer under the Australian
            Consumer Law. TravelbyBit makes no guarantees, warranties, or
            representations of any kind in relation to the provision of the
            Reward, except where a particular guarantee or warranty cannot be
            excluded under applicable law.
          </p>
          <p>
            Any dispute or claim arising out of or in connection with these
            terms shall be subject to the non-exclusive jurisdiction of the
            courts of Australia.
          </p>
        </div>
      </Container>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.6666;
    font-size: 0.9em;
  }

  table {
    color: #424242;
    font-size: 14px;
    margin-bottom: 30px;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
    width: 100%;

    th,
    td {
      padding: 10px 5px;
      border: 1px solid #aaa;
    }

    th {
      background: #f4f5f7;
      width: 15%;
      font-weight: bold;

      &:first-child {
        width: 20%;
      }
    }
  }
`
